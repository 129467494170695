var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Добавить пользователя",
      "width": 500,
      "actions": _vm.actions
    }
  }, [_c('FormValidatable', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('UiCenteredText', [_vm._v(" Введите Email, для которого будет создан дополнительный пользователь в рамках вашего аккаунта ")]), _c('div', {
    staticClass: "mt16"
  }, _vm._l(_vm.active_inputs, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "input-block"
    }, [_c('div', {
      staticClass: "input-block-title"
    }, [[_c('fade-transition', [item.show_error && item.touched ? _c('div', {
      staticClass: "input-error"
    }, [_vm._v(" " + _vm._s(item.error_text) + " ")]) : _vm._e()]), item.show_success && item.touched ? _c('img', {
      staticClass: "input-img",
      attrs: {
        "src": require(`@/assets/images/icons/success.svg`)
      }
    }) : _vm._e(), item.show_error && item.touched ? _c('img', {
      staticClass: "input-img img-error",
      attrs: {
        "src": require(`@/assets/images/icons/close.svg`)
      }
    }) : _vm._e()]], 2), _c('div', {
      staticClass: "input",
      class: {
        active: item.active
      }
    }, [(item.type === 'password' && item.show_password ? 'text' : item.type) === 'checkbox' ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.model,
        expression: "item.model"
      }],
      attrs: {
        "placeholder": item.placeholder,
        "autofocus": !i,
        "name": item.show_name !== false && item.name,
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(item.model) ? _vm._i(item.model, null) > -1 : item.model
      },
      on: {
        "focus": function ($event) {
          item.active = true;
        },
        "blur": function ($event) {
          return _vm.onBlur(item);
        },
        "change": function ($event) {
          var $$a = item.model,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(item, "model", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(item, "model", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(item, "model", $$c);
          }
        }
      }
    }) : (item.type === 'password' && item.show_password ? 'text' : item.type) === 'radio' ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.model,
        expression: "item.model"
      }],
      attrs: {
        "placeholder": item.placeholder,
        "autofocus": !i,
        "name": item.show_name !== false && item.name,
        "type": "radio"
      },
      domProps: {
        "checked": _vm._q(item.model, null)
      },
      on: {
        "focus": function ($event) {
          item.active = true;
        },
        "blur": function ($event) {
          return _vm.onBlur(item);
        },
        "change": function ($event) {
          return _vm.$set(item, "model", null);
        }
      }
    }) : _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.model,
        expression: "item.model"
      }],
      attrs: {
        "placeholder": item.placeholder,
        "autofocus": !i,
        "name": item.show_name !== false && item.name,
        "type": item.type === 'password' && item.show_password ? 'text' : item.type
      },
      domProps: {
        "value": item.model
      },
      on: {
        "focus": function ($event) {
          item.active = true;
        },
        "blur": function ($event) {
          return _vm.onBlur(item);
        },
        "input": function ($event) {
          if ($event.target.composing) return;
          _vm.$set(item, "model", $event.target.value);
        }
      }
    }), item.type == 'password' ? _c('svg', {
      staticClass: "icon-eye",
      attrs: {
        "width": "16",
        "height": "12",
        "viewBox": "0 0 16 12",
        "fill": "none",
        "xmlns": "http://www.w3.org/2000/svg"
      },
      on: {
        "click": function ($event) {
          item.show_password = !item.show_password;
        }
      }
    }, [_c('path', {
      attrs: {
        "d": "M5.87143 6C5.87143 6.5467 6.08796 7.07102 6.47338 7.45759C6.85879 7.84417 7.38153 8.06135 7.9266 8.06135C8.47166 8.06135 8.9944 7.84417 9.37982 7.45759C9.76524 7.07102 9.98176 6.5467 9.98176 6C9.98176 5.4533 9.76524 4.92898 9.37982 4.54241C8.9944 4.15583 8.47166 3.93865 7.9266 3.93865C7.38153 3.93865 6.85879 4.15583 6.47338 4.54241C6.08796 4.92898 5.87143 5.4533 5.87143 6ZM15.894 5.52515C14.1545 1.84969 11.525 0 8 0C4.47319 0 1.84551 1.84969 0.105961 5.52699C0.0361868 5.67518 0 5.83704 0 6.00092C0 6.1648 0.0361868 6.32666 0.105961 6.47485C1.84551 10.1503 4.47502 12 8 12C11.5268 12 14.1545 10.1503 15.894 6.47301C16.0353 6.17485 16.0353 5.82883 15.894 5.52515ZM7.9266 9.23926C6.14301 9.23926 4.69705 7.78896 4.69705 6C4.69705 4.21104 6.14301 2.76074 7.9266 2.76074C9.71019 2.76074 11.1561 4.21104 11.1561 6C11.1561 7.78896 9.71019 9.23926 7.9266 9.23926Z",
        "fill": "#C4C4C4"
      }
    })]) : _vm._e()])]);
  }), 0)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };